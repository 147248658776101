<template>
	<div id="Roll">
		<div class="addrollbac">
			<p class="addroll" @click="addRolls()">创建roll</p>

			<div class="Roll_Nav" style="flex-wrap: wrap;">
				<p class="" v-if="$store.state.User.anchor == 1" :class="{'active' : Status == '4'}" @click="auditRolls('4')">审核中</p>
				<p :class="{'active' : Status == '2'}" @click="ChangeRolls('2')">已结束</p>
				<p :class="{'active' : Status == '1'}" @click="ChangeRolls('1')">进行中</p>
				<p :class="{'active' : Status == '3'}" @click="ChangeRolls('3')">我参加的</p>
			</div>
		</div>

		<div class="roll-item" v-for="(item,index) in RoomList" :key="index" @click="toRoom(item.id)">
			
			<div class="roll-item-user">
				<img :src="item.user.avatar" alt="">
				<div>{{item.name}}</div>
			</div>
			
			<div class="roll-item-list" style="margin:0 auto;">
				<div class="roll-item-item" >
					<div class="pic">
						<img :src="item.box_records[0].cover" alt="">
					</div>
					
				</div>
				
				<div class="" style="display: flex;justify-content: space-between;padding: 0 22px;color: #fff;align-items: center;flex-wrap: wrap;">
					<div style="position:relative; " >
				<div class="roll-item-time" >
					ROLL时间：{{item.end_time}}
				</div>
			</div>
				<div style="font-size:16px" class="numbottom">
					<div>
						<img src="../../assets/images/q2/roll/gong.png" alt="" style="width:25px"><span  style="color: #f1bc60;">{{item.box_records.length}}</span>
					</div>
					<div>
						<img src="../../assets/images/q2/roll/propeo.png" alt="" style="width:25px"><span  style="color: #f1bc60;">{{item.join_number}}</span>
					</div>
					<div class="" style="display: flex;align-items: center">
						<span style="color: #f1bc60; display: flex;align-items: center">
								<img style="width: 20px" src="@/assets/images/public/Gold.png" alt="">{{item.award_bean}}</span>
					</div>
				</div>
				
			</div>
			</div>
			
			<!-- <div class="roll-item-open" :class="item.status == '1'?'active':''" @click="toRoom(item.id)">
				{{item.status == '1'?'进入房间':item.status == '2'?'查看详情':'房间待审核'}}
			</div> -->

		</div>



		<div v-if="false" class="Roll_List">
			<div class="Roll_item" v-for="(item,index) in RoomList" :key="index">
				<div class="Roll_pic">
					<img :src="item.user.avatar" alt="">
				</div>

				<div class="Roll_tit">{{item.name}}</div>
				<div class="Roll_Data">
					<div class="Roll_data_box">
						<p>奖池</p>
						<span style="color: #f1bc60;">
							<img src="@/assets/images/public/Gold.png" alt="">{{item.award_bean}}</span>
					</div>
					<div class="Roll_data_box">
						<p>件数</p>
						<span>{{item.box_records.length}}</span>
					</div>
					<div class="Roll_data_box">
						<p>人数</p>
						<span>{{item.join_number}}</span>
					</div>
				</div>
				<div class="Roll_Goods">
					<div class="Roll_Goods_item" v-for="(value,index) in item.box_records" :key="index" :style="value.lv | StyChoice">
						<div class="pic" :style="{'background-image': 'url('+value.lv_bgImage+')'}">
							<img :src="value.cover" alt="">
						</div>
					</div>
				</div>
				<div class="Roll_btn" @click="toRoom(item.id)">{{item.status == '1' ? '我要参加' : '查看详情'}}</div>
				<div class="Roll_time">开奖时间：{{item.end_time}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Rooms,
		UserRooms,
	rollPending
	} from '@/network/api.js'
	export default {
		name: 'Roll',
		data() {
			return {
				RoomList: [], //房间列表
				Status: '1', //房间状态
			}
		},
		filters: {
			// StyChoice(val) {
			// 	// console.log(val)
			// 	if (val == '1') {
			// 		return 'border-bottom-color:#e6ba1c;background-image: linear-gradient(to top, rgba(230, 186, 28,.5), transparent)'
			// 	} else if (val == '2') {
			// 		return 'border-bottom-color:#d32ce6;background-image: linear-gradient(to top, rgba(211, 44, 230,.5), transparent)'
			// 	} else if (val == '3') {
			// 		return 'border-bottom-color:#834fff;background-image: linear-gradient(to top, rgba(131, 79, 255,.5), transparent)'
			// 	} else if (val == '4') {
			// 		return 'border-bottom-color:#4b69ff;background-image: linear-gradient(to top, rgba(75, 105, 255,.5), transparent)'
			// 	} else {
			// 		return 'border-bottom-color:#bbbbbb;background-image: linear-gradient(to top, rgba(187, 187, 187,.5), transparent)'
			// 	}
			// }
		},
		created() {
			this.GetRooms()
		},
	mounted() {
		// console.log(this.$store.state.User.anchor)
	},

		methods: {
			//创建roll房
			addRolls(){
				this.$router.push('/CreatingRooms')
			},
			// 主播审核列表
			auditRolls(v){
				this.Status = v
				rollPending().then(res => {
					console.log(res.data);
					this.RoomList = res.data.data.data
				})

			},
			//获取房间列表(初始)
			GetRooms() {
				Rooms('1', this.Status).then((res) => {
					// console.log(res.data.data.data)
					this.RoomList = res.data.data.data
				})
			},

			//参加房间记录(我参与的)
			GetUserRooms() {
				UserRooms().then((res) => {
					this.RoomList = res.data.data.data
				})
			},

			//改变房间列表
			ChangeRolls(v) {
				this.Status = v
				switch (v) {
					case '1':
						this.GetRooms()
						break;
					case '2':
						this.GetRooms()
						break;
					default:
						this.GetUserRooms()
				}
			},

			//路由跳转房间详情
			toRoom(id){
				// console.log(id)
				this.$router.push({
					path:'/room',
					query:{
						id:id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
.addrollbac{
	background: url('../../assets/images/985/roll/addrollbac.jpg') no-repeat center;
	background-size: 100% 100%;
	padding-top: 50px;
	padding-bottom: 50px;
}
.numbottom{
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	justify-content: space-around;
}
	.roll{
		&-item{
			margin: 20px auto;
			padding: 5px ;
			// border: 1px solid #fbfb0274;
			width: 90%;
			background-size: 100% 100%;
			// background-color: #131426 ;
			background: url('../../assets/images/985/roll/list.png') no-repeat center;
			background-size: 100% 100%;
			padding: 15px;
			padding-top: 30px;
			&-time{
				width: 100%;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				color:#fbfa02;
				font-size: 10px;
				margin-bottom: 5px;
			}
			&-user{
				// display: flex;
				// align-items: center;
				height: 30px;
				line-height: 40px;
				width: 90%;
				// padding: 16px 22px;
				color: #FFF;
				>div{
					// display: inline-block;
					height: 100%;
					line-height: 40px;
					font-style: italic;
					font-size: 18px;
					font-weight: 900;
					margin-top: -10px;
				}
				& img{
					margin: 5px;
					float: left;
					width:30px;
					height:30px;
					border-radius: 50%;
				}
			}
			&-list{
				display: flex;
				flex-wrap: nowrap;
				height: 86px;
				overflow: hidden;
				margin-bottom: 10px;
				width: 90%;
				// background: rgba(0, 0, 0, 0.5);
			}
			&-item{
				display: flex;
				width: 100px;
				height: 60px;
				margin: 10px;
				// background-color: rgba(252, 36, 106, 0.2);
    			// border: 1px solid #FC246A;
				background: url('../../assets/images/985/roll/item.png') no-repeat center;
				background-size: 100% 100%;
				& .pic{
					width: 100%;
					height: 50px;
					& img{
						width: 100%;
					}
				}
			}
			&-open{
				margin-top: 10px;
				width: 100%;
				height: 25px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				border-top: 1px solid #ECB105;
			}
			& .active{
				background-image: linear-gradient(90deg, #ECB105, #EC7205);

			}
		}
	}
	#Roll {
		width: 100%;
		padding-bottom: 20px;
		.addroll{
			// border: 1px solid rgb(251,250,2);
			height: 100%;
			width: 100px;
			color: white;
			padding: 0 5px;
			// background: rgba(255, 255, 255, 0.1) ;
			background-color:transparent; 
			text-align: center;
			// border-top: 1px solid rgb(251,250,2);
			// border-left: 1px solid rgb(251,250,2);
			// border-right: 1px solid rgb(251,250,2);
			// border: 1px solid #fff;
			line-height: 30px;
			font-size: 11px;
			background: url('../../assets/images/q2/roll/title.png') no-repeat center;
			background-size: 100% 100%;
			margin: 0 auto;
			
		}
		.Roll_Nav {
			width: 100%;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			flex-direction: column;
			justify-content: space-evenly;
			align-content: flex-start;
			margin-top: 10px;
			align-content: space-around;
			margin-top: 20px;
			p {
				// border: 1px solid rgb(251,250,2);
				height: 100%;
				width: 70px;
				color: white;
				padding: 0 5px;
				// background: rgba(255, 255, 255, 0.1) ;
				background-color:transparent; 
				text-align: center;
				// border-top: 1px solid rgb(251,250,2);
				// border-left: 1px solid rgb(251,250,2);
				// border-right: 1px solid rgb(251,250,2);
				// border: 1px solid #fff;
				line-height: 30px;
				font-size: 11px;
				background: url('../../assets/images/q2/roll/title.png') no-repeat center;
				background-size: 100% 100%;
				// color: #fff;
				// margin: 0 5px;
				// border-radius: 4px;
				&.active {
					// background:rgb(251,250,2);
					background: url('../../assets/images/q2/roll/active.png') no-repeat center;
					background-size: 100% 100%;
					color: white;
					// border: 1px solid #ffd322;
				}
			}
		}

		.Roll_List {
			width: 100%;
			padding: 0 30px;
			box-sizing: border-box;
			// .roll_item{
			// 	border: 1px solid rgb(236,177,5);
			// }
			.Roll_item {
				width: 100%;
				background: url(../../assets/images/rooms/roll_item3.png) no-repeat center;
				background-size: cover;
				border-radius: 16px;
				position: relative;
				padding-top: 50px;
				padding-bottom: 20px;
				margin-top: 50px;
				border: 1px solid rgb(251,250,2) !important;
				

				.Roll_pic {
					position: absolute;
					left: 50%;
					top: -30px;
					width: 70px;
					height: 70px;
					border-radius: 50%;
					overflow: hidden;
					transform: translateX(-50%);
					border: 2px solid #ffba2e;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 130%;
						height: 130%;
					}
				}

				.Roll_tit {
					text-align: center;
					font-size: 14px;
					color: #fff;
				}

				.Roll_Data {
					width: 100%;
					display: flex;
					justify-content: center;
					margin-top: 10px;

					.Roll_data_box {
						width: 30%;
						text-align: center;

						p {
							color: #aaa;
							font-size: 12px;
						}

						span {
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 14px;
							color: #fff;
							font-weight: bold;
							margin-top: 10px;

							img {
								width: 18px;
								margin-right: 1px;
							}
						}
					}
				}

				.Roll_Goods {
					width: 100%;
					height: 70px;
					margin-top: 10px;
					display: flex;
					padding: 0 12px;
					box-sizing: border-box;
					justify-content: space-around;
					flex-wrap: wrap;
					overflow: hidden;

					.Roll_Goods_item {
						width: 30%;
						height: 70px;
						border-bottom: 2px solid transparent;
						box-sizing: border-box;

						.pic {
							width: 90%;
							height: 90%;
							margin: 2px auto 0;
							display: flex;
							justify-content: center;
							align-items: center;
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;

							img {
								max-width: 90%;
								max-width: 90%;
							}
						}
					}
				}

				.Roll_btn {
					width: 100px;
					height: 32px;
					margin: 20px auto;
					border: 2px solid #ffd322;
					border-radius: 20px;
					text-align: center;
					line-height: 32px;
					color: #ffd322;
					font-size: 14px;
				}

				.Roll_time {
					text-align: center;
					color: #fff;
					font-size: 14px;
				}
			}

		}


	}
</style>
